<template>
	<div>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-spacer></v-spacer>
				<v-col cols="12">
					<v-card v-bind="customCard">
						<v-data-table :headers="headers" :items="brandGroups" :loading="listStatus === status.RUNNING"
									  disable-filtering disable-pagination disable-sort hide-default-footer
									  loading-text="Loading... Please wait">
							<template v-slot:top>
								<v-toolbar id="brand-groups-toolbar" class="rounded-t" flat>
									<v-toolbar-title>
										Groups
									</v-toolbar-title>

									<v-spacer></v-spacer>

									<v-btn @click="" class="mr-2" color="primary">
										<v-icon>
											mdi-plus
										</v-icon>
									</v-btn>

									<v-btn @click="listBrandGroups" color="primary">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>
								</v-toolbar>
							</template>

							<template v-slot:item.name="props">
								<v-edit-dialog @save="updateBrandGroup(props.item)" :return-value.sync="props.item.name"
											   large>
									{{ props.item.name }}
									<template v-slot:input>
										<div class="mt-4 mb-2 text-h6">
											Update Name
										</div>
										<v-text-field v-model="props.item.name"
													  :rules="[validation.required, validation.notEmpty]" counter="200"
													  dense label="Name" outlined single-line></v-text-field>
									</template>
								</v-edit-dialog>
							</template>

							<template v-slot:item.sequence="props">
								<v-edit-dialog @save="updateBrandGroup(props.item)"
											   :return-value.sync="props.item.sequence" large>
									{{ props.item.sequence }}
									<template v-slot:input>
										<div class="mt-4 mb-2 text-h6">
											Update Sequence
										</div>
										<v-slider v-model="props.item.sequence" :max="brandGroups.length" min="1"
												  step="1" thumb-label ticks></v-slider>
									</template>
								</v-edit-dialog>
							</template>

							<template v-slot:item.action="props">
								<v-btn @click="" icon>
									<v-icon>
										mdi-delete
									</v-icon>
								</v-btn>
							</template>

						</v-data-table>
					</v-card>
				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>

		<!--		<card-dialog :showing="createDialog" @cancel="createDialog = false" @confirmed="createBrandGroup(category)"
							 :confirm-disabled="!valid" confirm-text="Create" max-width="600px"
							 title="Create Brand Group">
					<template v-slot:dialog-body>
						<v-form v-model="newCategoryForm" ref="newCategoryForm">
							<v-row dense>
								<v-col cols="12">
									<v-text-field v-model="category.name" :id="newUUID()"
												  :rules="[validation.required, validation.notEmpty]"
												  autocomplete="new-password" counter="100" dense label="Name"
												  outlined></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-select v-model="category.accessoryGroupId" :items="accessoryGroups"
											  :rules="[validation.required]" dense item-text="name" item-value="id"
											  label="Accessory Group" outlined></v-select>
								</v-col>
							</v-row>
						</v-form>
					</template>
				</card-dialog>-->
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CardDialog from '@/components/global/CardDialog'

export default {
	name: 'BrandGroupsIndex',
	components: {CardDialog},
	data: () => ({
		headers: [
			{text: 'Name', align: 'left', value: 'name', width: '40%'},
			{text: 'Sequence', value: 'sequence', width: '30%'},
			{text: 'Maintainer', value: 'lastMaintainedBy', width: '20%'},
			{text: 'Delete', value: 'action', align: 'right', width: '10%'},
		],
	}),
	created() {
		this.listBrandGroups()
	},
	methods: {
		...mapActions('appliance/brandGroup', [
			'listBrandGroups',
			'createBrandGroup',
			'updateBrandGroup'
		]),
	},
	computed: {
		...mapGetters('appliance/brandGroup', [
			'brandGroups',
			'listStatus',
			'createStatus',
			'updateStatus'
		])
	}
}
</script>

<style>
.v-data-table-header-mobile {
	display: none;
}
</style>
